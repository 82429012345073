import React, {useState, useEffect, useRef, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {loginSelector, login, setEmail, clearLoginError} from '../slices/login';
import routes from '../helpers/routes';
import {object as ObjectUtils} from '../helpers/utils';
import useLocalStorage from 'react-use-localstorage';
import {useDebounce} from "use-debounce";

import * as EmailValidator from 'email-validator';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import {FormattedMessage, useIntl} from 'react-intl';

import GoogleConnectButton from '../components/GoogleConnectButton/GoogleConnectButton';
import ChangeEmailButton from '../components/ChangeEmailButton';
import PasswordInput from '../components/PasswordInput';
import {withClientConfig} from '../providers/ClientConfigProvider';

import LoadingApp from "../components/LoadingApp";
import {setNotification} from "../slices/notification";
import qs from "qs";
import AppleConnectButton from "../components/AppleConnectButton/AppleConnectButton";
import DividerText from "../components/DividerText";
import LoginFooter from "../components/SignUp/LoginFooter";
import CenteredBox from "../components/CenteredBox";
import Api from "../helpers/api";
import useNotification from "../helpers/useNotification";

const Login = ({clientConfig, location}) => {
    const dispatch = useDispatch();
    const notification = useNotification();
    const {loginError, loginErrorMessage, loginNotificationType, isAuthenticated, email} = useSelector(loginSelector);
    const [, setOauthProvider] = useLocalStorage('oauthProvider', null);

    const intl = useIntl();
    const logoUrl = ObjectUtils.get('client.brand.logo.default', clientConfig, '');

    const [, setContinueUrl] = useLocalStorage('continueUrl', null);
    const [currentStatus, setCurrentStatus] = useState('enter_email');
    const [credentials, setCredentials] = useState({email, password: '', showPassword: false});
    const [inProgress, setInProgress] = useState(false);
    const [debouncedEmail] = useDebounce(credentials.email, 500);

    const emailRef = useRef();
    const passwordRef = useRef();

    const emailLabel = intl.formatMessage({
        id: 'Login.Email',
        defaultMessage: 'Email'
    });
    const passwordLabel = intl.formatMessage({
        id: 'Login.Password',
        defaultMessage: 'Enter your password'
    });

    useEffect(() => {
        emailRef.current && emailRef.current.focus();
    }, []);

    useEffect(() => {
        if (loginError) {
            setInProgress(false);
            dispatch(setNotification(loginErrorMessage, loginNotificationType));
        }
    }, [loginError, loginNotificationType, loginErrorMessage, dispatch])

    useEffect(() => {
        const isValidEmail = EmailValidator.validate(debouncedEmail);
        const newEmail = isValidEmail ? debouncedEmail : '';
        dispatch(setEmail(newEmail));
    }, [debouncedEmail, dispatch]);

    const handleCredentialsChange = (prop) => (event) => {
        dispatch(clearLoginError());
        const value = (prop === 'email' ? event.target.value.trim() : event.target.value);
        const newCredentials = {...credentials, [prop]: value};
        setCredentials(newCredentials);
        setCurrentStatus(`enter_${prop}`);
    };

    const handleClickContinueWithPassword = () => {
        setInProgress(true);
        if (EmailValidator.validate(credentials.email) && credentials.email.trim() !== '') {
            validateSsoDomain();
        } else {
            setCurrentStatus('invalid_email');
            setInProgress(false);
        }
    };

    const validateSsoDomain = () => {
        setInProgress(true);
        const domain = credentials.email.split('@')[1];
        Api.getIdentityProviderSettingsByDomain(domain)(
            ({data}) => {
                initializeContinueUrl();
                window.location.href = data.redirect_url;
            },
            (error) => {
                setInProgress(false);
                if ([404, 428].includes(error.response.status)) {
                    setCurrentStatus('enter_password');
                    setTimeout(() => passwordRef.current && passwordRef.current.focus(), 100);
                } else {
                    notification.captureError(error);
                }
            }
        )({email: credentials.email});
    };

    const handleClickLogin = () => {
        dispatch(clearLoginError());
        if (credentials.password.trim() === '') {
            setCurrentStatus('invalid_password');
        } else {
            setInProgress(true);
            dispatch(login(credentials));
        }
    };

    const handleClickOAuthConnect = (provider) => () => {
        setInProgress(true);
        initializeContinueUrl();
        setOauthProvider(provider);
        window.location.href = routes.getOAuthLoginUrlByProvider(provider);
    };

    const initializeContinueUrl = () => {
        const continueParam = getContinueUrlFromLocationQuery();
        if (continueParam) {
            setContinueUrl(continueParam);
        }
    }

    const getContinueUrlFromLocationQuery = () => {
        const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true});
        if (queryParams.hasOwnProperty('continue')) {
            return queryParams.continue;
        } else {
            return null;
        }
    }

    const onPressEnter = (callback) => (e) => {
        if (e.key === 'Enter') {
            callback();
        }
    };

    if (isAuthenticated !== false) {
        return <LoadingApp/>;
    } else {
        return (
            <CenteredBox title={"Log in"} showLogo inProgress={inProgress} footer={<LoginFooter/>} logoUrl={logoUrl}>
                {
                    (currentStatus === 'enter_email' || currentStatus === 'invalid_email') &&
                    <Box mt={2}>
                        <FormControl fullWidth>
                            <TextField label={emailLabel} variant="outlined"
                                       inputRef={emailRef}
                                       disabled={inProgress}
                                       onKeyUp={onPressEnter(handleClickContinueWithPassword)}
                                       onChange={handleCredentialsChange('email')}
                                       error={currentStatus === 'invalid_email'} value={credentials.email}
                                       autoFocus
                                       helperText={currentStatus === 'invalid_email' ? 'Invalid email' : ''}/>
                        </FormControl>

                        <Box mt={2}>
                            <Button variant="contained" color="primary" disabled={inProgress}
                                    onClick={handleClickContinueWithPassword}
                                    fullWidth>
                                <FormattedMessage id="Login.Next" defaultMessage="Next"/>
                            </Button>
                        </Box>
                    </Box>
                }

                {
                    ['enter_password', 'invalid_password'].includes(currentStatus) &&
                    <>
                        <Typography align="center" gutterBottom>
                            <ChangeEmailButton email={credentials.email} disabled={inProgress}
                                               onClick={() => setCurrentStatus('enter_email')}/>
                        </Typography>

                        <Box mt={2}>
                            <FormControl fullWidth>
                                <PasswordInput error={currentStatus === 'invalid_password'}
                                               label={passwordLabel}
                                               inputRef={passwordRef}
                                               disabled={inProgress}
                                               onKeyPress={onPressEnter(handleClickLogin)}
                                               onChange={handleCredentialsChange('password')}/>
                            </FormControl>

                            <Box mt={2}>
                                <Button color="primary" variant="contained" onClick={handleClickLogin} disabled={inProgress}
                                        fullWidth>
                                    <FormattedMessage id="Login.Next" defaultMessage="Next"/>
                                </Button>
                            </Box>
                        </Box>
                    </>
                }

                <Box mt={1}>
                    <DividerText text={"or"}/>
                </Box>

                <Box mt={1}>
                    <AppleConnectButton onClick={handleClickOAuthConnect('apple')} disabled={inProgress}/>
                </Box>

                <Box mt={2}>
                    <GoogleConnectButton onClick={handleClickOAuthConnect('google')} disabled={inProgress}/>
                </Box>

                <Box mt={1}>
                    <DividerText text={"or"}/>
                </Box>

                <Box mt={1}>
                    <Typography variant="body2" align="center">
                        <Link component={RouterLink} to={routes.getForgotPasswordUrl()}>
                            <FormattedMessage id="Login.ForgotPassword" defaultMessage="Forgot password?"/>
                        </Link>
                    </Typography>
                </Box>
            </CenteredBox>
        );
    }
}

export default withClientConfig(withRouter(Login));
